export const deviceTypeOptions = [
    { value: "05", label: "OpenAir" },
    { value: "06", label: "BaseSmart" },
    { value: "07", label: "Atmosphere" },
    { value: "2213", label: "CityAir" },
    { value: "32", label: "Waterfall" },
    { value: "43", label: "Vector" },
    { value: "44", label: "CityAir400" },
    { value: "24", label: "SmartEx" },
    { value: "07C", label: "Atmosphere Circle" },
    { value: "51", label: "KivSmart" },
];

export const deviceSubTypeOptions = [
    { value: "nidec", label: "nidec" },
    { value: "delta", label: "delta" },
    { value: "original-led", label: "original-led" },
    { value: "EISKUHL", label: "EISKUHL" },
    { value: "short", label: "short" },
    { value: "amp", label: "amp" },
    { value: "circle", label: "circle" },
    { value: "avc", label: "avc" },
    { value: "pfb", label: "pfb" },
    { value: "ADDA", label: "ADDA" },
    { value: "delta_PFB0812DHE", label: "delta_PFB0812DHE" },
    { value: "drv8870", label: "drv8870" },
    { value: "mhz19b", label: "mhz19b" },
    { value: "sensair", label: "sensair" },
    { value: "short-type", label: "short-type" },
    { value: "default", label: "default" },
    { value: "default-chip", label: "default-chip" },
    { value: "default-module", label: "default-module" },
    { value: "tmp8015-chip", label: "tmp8015-chip" },
    { value: "tmp8015-chip-m", label: "tmp8015-chip-m" },
];
